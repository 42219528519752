@import '../../public/styles/edit-photo.scss';
.edit-profile{
	.edit-photo{
		@extend .edit-photo;
    }
    .action{
    	.dflex{
    		@include mq(sm) {
    			display: block;
    		}
    	}
    }
}
@import '../../public/styles/variable.scss';
@import '../../public/styles/mixin.scss';
/* register screen */
.register-wrap{
	background-color: $authentication_bg;
	max-width: em(650px);
	width: 100%;
	margin: em(50px) auto em(15px);
	padding: em(40px) em(60px);
	position: relative;
	@include border-radius(5px);
	@include mq(sm) {
		margin: 0 auto;
		padding: em(20px);
	}
	.logo{
		display: block;
		text-align: center;
		margin-bottom: em(24px);
	}
	label.label{
		font-size: em(14px);
		color: $authentication_label;
		font-weight: 600;
		line-height: normal;
		text-transform: uppercase;
		margin-bottom: em(14px);
		display: block;
		text-align: center;
	}
	.form-control{
		border: em(01px) solid;
		border-color: $authentication_input_border;
		font-size: em(14px);
		color: $authentication_input_text;
		height: auto;
		padding: em(12px) em(14px);
		@include border-radius(3px);
		@include transition(all 1s ease-in-out);
		@include placeholder {
			color: $authentication_input_text;
		}
		&:focus{
			outline: none;
			box-shadow: none;
			border-color: $base_color;
		}
	}
	select{
		border: em(01px) solid;
		border-color: $authentication_input_border;
		font-size: em(14px);
		color: $authentication_input_text;
		height: em(40px);
		padding: 0 em(14px);
		width: 100%;
		background-color: $authentication_input_bg;
		-webkit-appearance: none;
	    -moz-appearance: none;
	    appearance: none;
	    //background: url("#{$image_path}/select-arrow.png") no-repeat right center;
	    &:focus{
			border-color: $base_color;
			box-shadow: none;
			outline: none;
	    }
	}
	.form-group{
		margin-bottom: em(15px);
		position: relative;
		.form-error{
			color: red;
			font-size: em(13px);
			display: block;
			margin-top: em(05px);
		}
	}
	.captcha{
		width: 100%;
		margin-bottom: em(20px);
		font-size: em(16px);
		display: flex;
		justify-content: center;
	}
	.action{
		display: block;
		.submit{
			display: block;
			width: 100%;
			background-color: $authentication_action_submit_bg;
			color: $authentication_action_submit_text;
			font-size: em(15px);
			font-weight: 600;
			border: 0px;
			padding: em(12px) em(15px);
			line-height: normal;
			cursor: pointer;
			margin-bottom: em(10px);
			@include border-radius(5px);
			@include transition(all 0.8s ease-in-out);
			&:hover, &:focus{
				background-color: $authentication_action_submit_bg_hover;
				outline: none;
				box-shadow: none;
			}
		}
		.signin{
			display: block;
			font-size: em(13px);
			color: $authentication_signup_text;
			font-weight: 400;
			text-align: center;
			line-height: normal;
			a{
				font-weight: 600;
				color: $authentication_signup_text;
				margin-left: em(03px);
				display: inline-block;
				@include transition(all 0.5 ease-in-out);
				&:hover, &:focus{
					color: $authentication_signup_text_hover;
				}
			}
		}
	}
}
@import '../../../public/styles/status-color.scss';
.team-detail{
	.team{
		.table-data{
			border: 1px solid #ededed;
			th{
				background-color: #fbfbfb;
				font-size: em(16px);
				font-weight: 600;
				text-transform: none;
				border: 1px solid #ededed;
				color: #626262;
			}
			td{
				background-color: #fff;
				font-size: em(16px);
				font-weight: 600;
				text-transform: none;
				border: 1px solid #ededed;
				padding: 0 em(10px);
				color: #626262;
			}
		}
		.map{
			width: 100%;
			height: 100%;
			iframe{
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
}

.button-link {
	background-color: transparent;
	padding: 0;
	border: 0;
	border-radius: 0px;
	color: #f15a24;
	font-weight: 600;
	font-size: 1em;
	cursor: pointer;
}
@import '../../../public/styles/status-color.scss';
.game-detail{
	.games{
		.table-data{
			border: 1px solid #ededed;
			th{
				background-color: #fbfbfb;
				font-size: em(16px);
				font-weight: 600;
				text-transform: none;
				border: 1px solid #ededed;
				color: #626262;
			}
			td{
				background-color: #fff;
				font-size: em(16px);
				font-weight: 600;
				text-transform: none;
				border: 1px solid #ededed;
				padding: 0 em(10px);
				color: #626262;
			}
		}
		.map{
			width: 100%;
			height: 100%;
			iframe{
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
	.asign-game{
		h2{
	        display: block;
	        margin-bottom: em(12px);
	        font-size: 24px;
	        color: $text_color;
	        font-weight: 600;
		}
		label.team {
			font-size: 1.5em;
		}
	    .wrap{
	    	background-color: #fafafa;
	    	border: 1px solid;
	    	border-color: #ededed;
	    	padding: em(30px) em(15px) em(20px);
	    }
	    .list-items{
	    	border-bottom: 1px solid;
	    	border-color: #dddddd;
	    	padding-bottom: em(10px);
	    	margin-bottom: em(15px);
	    	.post{
	    		font-size: em(16px);
	    		color: #4d4d4d;
	    		font-weight: 600;
	    		@include mq(lg) {
					display: block;
	    		}
	    	}
	    	.name-status{
	    		.status{
	    			display: inline-block;
	    			@extend .status-color;
	    		}
	    		.name{
	    			display: inline-block;
	    			vertical-align: middle;
	    			font-size: em(16px);
	    			color: #626262;
	    			line-height: 1;
	    			padding-left: 10px;
	    			width: calc(100% - 12px);
	    			.name{
	    				display: inline-block;
	    			}
	    			.surname{
	    				display: inline-block;
	    				margin-left: em(02px);
	    			}
	    		}
	    	}
	    }
	}
}

.drop-area {
	.dropzone {
		min-height: 200px;
		border: none;
	}
	section {
		position: relative;
		width: 100%;
		border: 2px dashed #C7C7C7;
		.inner-text {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
			text-align: center;
			.button-link {
				background-color: transparent;
				padding: 0;
				border: 0;
				border-radius: 0px;
				color: #f15a24;
				font-weight: 600;
				font-size: 1em;
				cursor: pointer;
				display: inline;
				margin-top: 10px;
			}
			.preview {
				border-radius: 20px;
				background: linear-gradient(to bottom, #eee, #ddd);
				padding: 10px 20px;;
				position: relative;
				display: block;
				z-index: 10;
				text-align: center;
				width: 120px;
				.details {
					.filename{
						text-overflow: ellipsis;
    					overflow: hidden;
					}
				}
				.progress {
					margin-top: 10px;
					z-index: 1000;
					pointer-events: none;
					background-color: #ffffff;
					.upload {
						background: linear-gradient(to bottom, #ea764d, #f15a24);
						transition: width 300ms ease-in-out;
					}
				}
				.dz-progress {
					margin-top: 15px;
				}
			}
			
		}
	}
}

.dropzone .percentage {
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 999;
}


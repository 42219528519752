@import '../../public/styles/variable.scss';
@import '../../public/styles/mixin.scss';

$fullcalendar_height: em(800px);
$fullcalendar_height_mobile: em(550px);
$name_max_width: em(210px);

.canvas-container{
    @include mq(x) {
    	height: 400px;
    }
    @include mq(sm) {
    	height: 300px;
    }
}
.canvas-container canvas{
	width: 100% !important;
	height: 100% !important;
}
.fullcalendar{
	height: $fullcalendar_height;
	@include mq(sm) {
		height: $fullcalendar_height_mobile;
	}
	.fc-event{
		padding: em(5px);
		background-color: transparent;
		border: 0;
		margin-top: em(-8px) !important;
		background: #f25a24;
		.fc-content{
			.fc-title{
				color: $base_color;
				font-weight: 600;
				font-size: em(14px);
				padding-left: 40px;
				display: block;
				color: #1f1f1f;
			}
			.fc-time{
				position: absolute;
				width: 40px;
				left: 0;
				top: 0;
			}
		}
	}
}
// .home-activity{
// 	.list{
// 		padding: 0;
// 		margin: 0;
// 		li:hover {
// 			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
// 		}
// 		li{
// 			cursor: pointer;
// 			padding: em(20px);
// 			border-bottom: 1px solid;
// 			border-color: $border_color_1;
// 			list-style: none;
// 			position: relative;
// 			.avatar{
// 				float: left;
// 				margin-right: em(22px);
// 			}
// 			.discription{
// 				padding-right: em(70px);
// 				margin-left: em(70px);
// 				@include mq(sm) {
// 					padding-right: 0;
// 				}
// 				.name{
// 					display: block;
// 					font-size: em(16px);
// 					font-weight: $fontWeight-semibold;
// 					color: $base_color;
// 					line-height: normal;
// 					margin-bottom: em(05px);
// 					max-width: $name_max_width;
// 					width: 100%;
// 					overflow: hidden;
// 					text-overflow: ellipsis;
// 					white-space: nowrap;
// 				}
// 				p{
// 					font-size: em(14px);
// 					margin-bottom: 0;
// 					color: $activity_content_color;
// 					b{
// 						font-weight: $fontWeight-regular;
// 						color: $base_color;
// 					}
// 					a{
// 						font-weight: $fontWeight-regular;
// 						color: $base_color;
// 					}
// 				}
// 			}
// 			.time{
// 				font-size: em(13px);
// 				color: $activity_time_color;
// 				width: em(60px);
// 				text-align: right;
// 				position: absolute;
// 				right: 0;
// 				top: em(20px);
// 			}
// 			&:first-child{
// 				padding-top: 0;
// 				.time{
// 					top: 0;
// 				}
// 			}
// 		}
// 	}
// }

.home-activity{
		.list{
			li:hover {
				box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
			}
			li { 
				cursor: pointer;
				padding: em(10px);
				border-bottom: 1px solid;
				border-color: $border_color_1;
				list-style: none;
				.time{
					font-size: em(13px);
					color: $activity_time_color;
				}
				.discription{
					.name{
						font-size: em(16px);
						font-weight: $fontWeight-semibold;
						color: $base_color;
						line-height: normal;
						margin-bottom: em(05px);
						white-space: nowrap;
						max-width: $name_max_width;
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					p{
						font-size: em(14px);
						margin-bottom: 0;
						color: $activity_content_color;
						b{
							font-weight: $fontWeight-regular;
							color: $base_color;
						}
						a{
							font-weight: $fontWeight-regular;
							color: $base_color;
						}
					}
				}
			}
		}
}
.home-activity-action{
	display: block;
	text-align: center;
	font-size: em(16px);
	color: $activity_action_color;
	font-weight: $fontWeight-semibold;
	padding: em(10px) 0;
	.link{
		display: inline-block;
		color: $activity_action_color;
		background-color: transparent;
		border: 0;
		padding: 0;
		font-weight: $fontWeight-semibold;
		&:hover{
			color: $base_color;
		}
	}
}

/*---20-03-19---*/
.fullcalendar{
	.fc-body{
		.fc-day-top{
			padding: 0.121429em 0.07143em;
		}
	}
}

.fc-unthemed{
	.fc-popover{
		.fc-content{
			white-space: normal;
		}
	}
}

.fc-more-popover{
	.fc-event-container{
		max-height: 450px;
		overflow-y: auto;
	}
}

.fc-basicDay-view{
	.fc-event{
		.fc-content{
			.fc-time{
				width: 80px;
			}
		}
	}
}

.fc-basicDay-view{
	.fc-event{
		.fc-content{
			.fc-title{
				padding-left: 80px;
			}
		}
	}
}
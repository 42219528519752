@import '../../../public/styles/status-color.scss';
.game-list-page{
	.popover{
		&.show{
			.popover-body{
				font-weight: 600;
			}
		}
	}
	.actions{
		display: block;
		.list-inline-item{
			margin-right: em(15px);
			&:last-child{
				margin-right: 0;
			}
		}
	}
	.game-list{
		padding: em(10px) 0px;
		.list-inline{
			li{
				margin: 0 em(15px) em(05px);
			}
		}
		.status{
			@extend .status-color;
		}
		.name{
			display: inline-block;
			.names{
				display: inline-block;
				vertical-align: top;
			}
			.surname{
				display: inline-block;
				vertical-align: top;
				margin-left: em(05px);
			}
		}
		.post{
			display: inline-block;
			margin-left: em(05px);
			text-transform: uppercase;
			font-weight: 600;
		}
	}

	.disabled-datepicker {
		background-color: #ddd;
	}
}

.btn.btn-active {
	background-color: #f15a24;
	border-color: #f15a24;
	color: #fff;
	&:hover {
		background-color: #1f1f1f;
    border-color: #1f1f1f;
    color: #fff;
	}
}

.btn.btn-standard {
	background-color: #fff;
	border-color: #e5e5e5;
	color: #1f1f1f;
	&:hover {
		background-color: #f15a24;
    border-color: #f15a24;
    color: #fff;
	}
}